import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CampNoDatatxtWrp,
  FullSlot,
  // IconBox,
  // Iconwpr,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  SlotOverviewWrapper,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
// import PortalTooltip from '../../portal-tooltip';
// import GraphTooltip from '../../graph-tooltip';
// import { graphTypes, widgetMapping } from '../../../constants/widgets';
import { ScatterPlot3D } from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import {
  CommentaryLabel,
  CommentarySection,
  // CommentaryLabel,
  // CommentarySection,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import { gridLine } from '../../../constants/widgets';
import ChartToolTip from '../../chart-tool-tip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import { colorBox } from '../../../graphs/utils/graphConst';

// const gridXTicksCount = 6;

// const generateGraphComponent = (
//   widget,
//   defaultConfig,
//   type,
//   dashboardType,
//   canvas,
//   resetSelection = false
// ) => {
//   // console.log(widgetMapping, dashboardType, widget.component);

//   const widgetDetails = {
//     dashboardType,
//     type,
//     component: widget.component,
//   };

//   const { bentoView } =
//     (widgetMapping[dashboardType] &&
//       widgetMapping[dashboardType][widget.component]) ||
//     {};

//   const GraphComponent =
//     type === 'dashboard' || type === 'l2'
//       ? graphTypes[widget.graphType].component
//       : bentoView[type]?.component;

//   const dataCount = widget?.data?.data?.length;
//   const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

//   const canvasConfig = { gridXTicks: xTicksCount };

//   const finalConfig = {
//     ...(type === 'dashboard' || type === 'l2'
//       ? graphTypes[widget.graphType].config
//       : bentoView[type]?.config),
//     ...defaultConfig,
//     ...widgetDetails,
//     ...(canvas && canvasConfig),
//   };

//   return (
//     <GraphComponent
//       data={widget.data}
//       config={finalConfig}
//       resetSelection={resetSelection}
//     />
//   );
// };

const CampaignMonitor = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  customClassName,
  helperText = '',
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: {
          ...tData,
          value: tData?.articleCount,
        },
        rawData: d?.rawData,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  const legendData = [
    {
      label: 'X-Axis : Net Sentiment',
      color: '#fff',
    },
    {
      label: 'Y-Axis : Reach',
      color: '#fff',
    },
    {
      label: 'Size of Bubbles : Number of Articles',
      color: '#fff',
    },
  ];

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [helperText]);

  return (
    <>
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp className={customClassName}>
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotTitle ref={titleRef}>Campaign Monitor</SlotTitle>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setChartToolTip(true)}
                  onMouseLeave={() => setChartToolTip(false)}
                >
                  <HelpIcon />
                </span>
              </SlotHeaderLeft>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>

              {chartToolTip && (
                <ChartToolTip
                  text={helperText}
                  iconTop={-9}
                  componentTop={7}
                  componentLeft={iconPosition?.left}
                />
              )}
            </SlotHeader>
            {/* <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader> */}
            <SlotBody type={type} className="commentary">
              <SlotBodyMain>
                {loader ? (
                  <Loader />
                ) : widget?.shouldShowGraph ? (
                  <CampNoDatatxtWrp>No Data</CampNoDatatxtWrp>
                ) : (
                  <ScatterPlot3D
                    data={widget}
                    config={{
                      xAxisType: 'number',
                      yLabelAlignment: 30,
                      gridXTicks: 12,
                      gridYTicks: 6,
                      overTimeChart: true,
                      hideYAxisLine: true,
                      valueInPercent: true,
                      ...gridLine,
                      ...defaultConfig,
                    }}
                    resetSelection={resetSelection}
                  />
                )}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                  >
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={widget}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
            </SlotBody>
            <SlotFooter>
              <LegendCon>
                {legendData.map((ele, i) => {
                  return (
                    <LegendItem key={i}>
                      {/* <LegendBox legendColor={ele?.color}></LegendBox> */}
                      <LegendLabel>{ele?.label}</LegendLabel>
                    </LegendItem>
                  );
                })}
              </LegendCon>

              <SlotBodyHeaderRight>
                {' '}
                <LegendCon>
                  {widget?.data?.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele.color}></LegendBox>
                        <LegendLabel>{ele?.label}</LegendLabel>
                      </LegendItem>
                    );
                  })}
                </LegendCon>
              </SlotBodyHeaderRight>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
    </>
  );
};

export default CampaignMonitor;

CampaignMonitor.defaultProps = {
  type: 'dashboard',
};

CampaignMonitor.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
};
