import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CongruenceNoDataTxt,
  FullSlot,
  // IconBox,
  // Iconwpr,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  MessageWrp,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  WidgetSubLabel,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import {
  // CommentaryLabel,
  // CommentarySection,
  SlotOverviewWrapper,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import Tooltip from '../../icon-tooltip';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  // console.log(widgetMapping, dashboardType, widget.component);

  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    textLabelFlag: false,
    enableRectLabels: false,
    showAllLabels: false,
    labelStraight: true,
    hideYAxisLine: true,
    haveGradient: true,
    barSpacing: true,
    topLabels: true,
    isCongruence: true,
  };
  return (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const Congruence = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  customClassName = '',
  helperText,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: tData,
        rawData: {
          ...d?.rawData,
          message_congruence: d?.rawData?.doc_count,
          Online: d?.rawData?.OnlineDocCount,
          Print: d?.rawData?.PrintDocCount,
        },
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 24, // Added width of icon and padding
      });
    }
  }, [helperText]);

  return (
    <>
      <FullSlot className="graph-widget override-padding">
        <SlotDetailsMainWrp className={customClassName}>
          {/* <IconBox>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //   onClick={handleGraphEditClick}
            >
              <Edit2 />
            </Iconwpr>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              //  onClick={handleClick}
            >
              <VerticleDots />
            </Iconwpr>
          </IconBox> */}
          <SlotDetailsWrp>
            <SlotHeader className="hide-download">
              <SlotHeaderLeft>
                <SlotTitle ref={titleRef}>Message Congruence</SlotTitle>
                <span
                  style={{ marginTop: '0.25rem', cursor: 'pointer' }}
                  onMouseEnter={() => setChartToolTip(true)}
                  onMouseLeave={() => setChartToolTip(false)}
                >
                  <HelpIcon />
                </span>
              </SlotHeaderLeft>
              {chartToolTip && (
                <ChartToolTip
                  text={helperText}
                  componentTop={6}
                  componentLeft={iconPosition?.left}
                />
              )}
            </SlotHeader>
            <SlotBodyHeader>
              <SlotOverviewWrapper className="hide-download">
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
            </SlotBodyHeader>
            <SlotBody type={type} className="commentary">
              <SlotBodyMain>
                {loader ? (
                  <Loader />
                ) : widget?.shouldShowGraph ? (
                  <CongruenceNoDataTxt>No Data</CongruenceNoDataTxt>
                ) : (
                  generateGraphComponent(
                    widget,
                    defaultConfig,
                    type,
                    dashboardType,
                    canvas,
                    resetSelection
                  )
                )}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                  >
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={widget}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
              <MessageWrp>
                {widget?.data?.data?.map((ele, i) => {
                  return (
                    <WidgetSubLabel
                      dataLen={widget?.data?.data?.length}
                      key={i}
                    >
                      <Tooltip content={ele?.subLabel}>{ele?.subLabel}</Tooltip>
                    </WidgetSubLabel>
                  );
                })}
              </MessageWrp>
            </SlotBody>
            {/* <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter> */}
            <SlotBodyHeaderRight>
              <LegendCon>
                {widget?.data?.labels
                  ?.slice()
                  ?.reverse()
                  ?.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele?.color}></LegendBox>
                        <LegendLabel>{ele?.label}</LegendLabel>
                      </LegendItem>
                    );
                  })}
              </LegendCon>
            </SlotBodyHeaderRight>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
    </>
  );
};

export default Congruence;

Congruence.defaultProps = {
  type: 'dashboard',
};

Congruence.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
};
