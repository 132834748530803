/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import X from '../../assets/icons/X';
import { Button } from '../../components/button';
import {
  CrossIconWrp,
  ButtonWrap,
  TermsBackGroundText,
  TermsContainer,
  TermsContentContainer,
  TermsContentWrp,
  TermsTitleWrp,
  TitleWrap,
  ContentText,
  Loaderwrp,
} from './index.sc';
import { getCreatePassword } from '../../redux/slices/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getTokenData } from '../../constants/validateToken';
import { logAwsRumEvent } from '../../utils';
import { eventNames, loginTypeCreatePassword } from '../../constants';
import * as Sentry from '@sentry/react';
import { theme } from '../../constants/theme';
import { useSelector, useDispatch } from 'react-redux';
import AppFooter from '../../components/app-footer';
import {
  useGetTermsConditionStatus,
  useUpdateTermsCondition,
} from '../../hooks/useSettings';

const TermsAndConditionsComp = () => {
  const [btnTxt, setBtnTxt] = useState('Agree and Proceed');
  const [isAgreed, setIsAgreed] = useState();
  const [termsConditionsContent, setTermsConditionsContent] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const { mutateAsync: updateTermsStatus } = useUpdateTermsCondition();
  const { mutateAsync: getTermsConditionStatus } = useGetTermsConditionStatus();

  useEffect(() => {
    setLoader(true);
    const handleTermsStatus = async () => {
      const resp = await getTermsConditionStatus(location?.state?.email);
      setIsAgreed(resp?.data?.data?.is_agreed);
      setTermsConditionsContent(resp?.data?.data?.terms_data);
      setLoader(!resp?.isSuccessful);
    };
    handleTermsStatus();
  }, []);

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const handleSubmit = async () => {
    setBtnTxt('Please wait...');
    try {
      const termResp = await updateTermsStatus({
        email: location.state.email,
        terms_status: 'True',
      });

      if (termResp?.isSuccessful && !isAgreed) {
        const response = await dispatch(
          getCreatePassword({
            id: location.state.id,
            newPassword: location.state.newPassword,
            confirmPassword: location.state.confirmPassword,
          })
        );
        if (response?.type === 'user/createPassword/fulfilled') {
          navigate('/otp-verification-login', {
            state: {
              email: location.state.email,
              otpType: loginTypeCreatePassword,
              id: location.state.id,
            },
          });
          setTimeout(() => {
            toast.success('Please verify your registered email for Code');
          }, 2000);
        } else {
          toast.error('An error occurred during the API call.');
        }
      } else {
        toast.error('Failed to set the password, Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred during the API call.');
      const tokenData = getTokenData();
      logAwsRumEvent(eventNames.function_error_handled_log, {
        error: error?.stack,
        type: 'crash detected',
        id: tokenData?.user_id || 'not logged in',
        name: error.name,
      });
      Sentry.captureException(error);
    } finally {
      setBtnTxt('Agree and Proceed');
    }
  };

  return (
    <>
      <TermsContainer>
        <TermsBackGroundText>
          {' '}
          Build
          <br />
          Analytics with
          <br />
          intelligence
          <br />
        </TermsBackGroundText>
        <TermsContentContainer>
          <TermsTitleWrp>
            <TitleWrap>Terms and Conditions</TitleWrap>
            <CrossIconWrp onClick={() => navigate(-1)}>
              <X size="20" />
            </CrossIconWrp>
          </TermsTitleWrp>
          <TermsContentWrp>
            {loader ? (
              <Loaderwrp>
                <div className="loading">
                  <p style={{ margin: '0px' }}>Loading</p>
                  <svg
                    width="24"
                    height="6"
                    viewBox="0 0 24 6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#5F39F8"
                  >
                    <circle cx="3" cy="3" r="3">
                      <animate
                        attributeName="opacity"
                        values="0;1;0"
                        dur="1.5s"
                        repeatCount="indefinite"
                        begin="0s"
                      />
                    </circle>
                    <circle cx="12" cy="3" r="3">
                      <animate
                        attributeName="opacity"
                        values="0;1;0"
                        dur="1.5s"
                        repeatCount="indefinite"
                        begin="0.5s"
                      />
                    </circle>
                    <circle cx="21" cy="3" r="3">
                      <animate
                        attributeName="opacity"
                        values="0;1;0"
                        dur="1.5s"
                        repeatCount="indefinite"
                        begin="1s"
                      />
                    </circle>
                  </svg>
                </div>
              </Loaderwrp>
            ) : (
              Object.entries(termsConditionsContent)?.map(
                ([keys, value], id) => (
                  <React.Fragment key={id}>
                    <ContentText fontWeight={700} textTransform={true}>
                      {keys?.replace(/_/g, ' ')}
                    </ContentText>
                    <ContentText>{value}</ContentText>
                  </React.Fragment>
                )
              )
            )}
          </TermsContentWrp>

          <ButtonWrap>
            <Button
              title={'Disagree'}
              backgroundColor={theme[selectedTheme].background}
              color={theme[selectedTheme].primary}
              onClick={() => navigate(-1)}
              border={theme[selectedTheme].primary}
            />
            <Button
              title={btnTxt}
              backgroundColor={theme[selectedTheme].primary}
              onClick={handleSubmit}
            />
          </ButtonWrap>
        </TermsContentContainer>
      </TermsContainer>
      <AppFooter termsAndCondition={false} />
    </>
  );
};

export default TermsAndConditionsComp;
