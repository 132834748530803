import { useQuery } from '@tanstack/react-query';
import { get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import {
  volumeAnalysis,
  topJournalistBySentiment,
  topSourceBySentiment,
  peopleCoverage,
  peoplePopularTopics,
  mediaType,
} from './data/peopleData';
import {
  addCountPrefix,
  calculatePercentageIncrease,
  toCamelCase,
} from '../constants/utils';
import { format, parseISO } from 'date-fns';
import { getFormattedDate } from './useCharts';
import { colors } from './data/colors';
import { purpleColorGradients } from '../constants/graph-colors';

const social = ['X (Twitter)', 'Blogs', 'Forums', 'Reviews', 'Reddit'];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocial = filters?.media_types?.some((x) => social?.includes(x));
    const isTraditional = filters?.media_types?.some((x) =>
      traditional?.includes(x)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const socialCardMapping = [
  'Total Mentions',
  'Social Reach',
  'Total Engagement',
  'Social Unique Users',
];

const traditionalMapping = ['Total Articles', 'Total Reach', 'Total AVE'];
const all = [
  'Total Articles',
  'Traditional Reach',
  'Total Mentions',
  'Social Reach',
  'Total AVE',
  'Total Engagement',
  'Social Unique Users',
];

const getTitle = (tile, selectedMedia) => {
  if (tile?.label === 'Social Unique Users') {
    return 'Unique Users';
  } else if (
    tile?.label === 'Traditional Reach' &&
    selectedMedia === 'traditional'
  ) {
    return 'Total Reach';
  }
  return tile?.label;
};

const mediaMappings = {
  social: socialCardMapping,
  all,
  traditional: traditionalMapping,
};

// people dashboard volume analysis
const getDashboardVolumeAnalysisData = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-people/volume-analysis?${queryString}`,
    {}
  );

  const volumAnalysisMapData = JSON.parse(JSON.stringify(volumeAnalysis));

  const colorData = volumAnalysisMapData?.data?.data;
  const selectedMedia = getSelectedTypes(payload);

  const filteredData = response?.filter((tile) => {
    const cards = mediaMappings[selectedMedia];
    if (!cards?.includes(tile?.label)) {
      return false;
    }
    return true;
  });

  const data = filteredData
    ?.map((x, i) => {
      const { isIncreased, percentageIncrease: change } =
        calculatePercentageIncrease(x?.current_count, x?.previous_count);
      const colorIndex = i % colorData?.length;
      const cards = mediaMappings[selectedMedia];
      const order = cards.findIndex((card) => card === x?.label);
      return {
        label: getTitle(x, selectedMedia),
        value: x?.current_count,
        change,
        isIncreased,
        color: colorData[colorIndex]?.color,
        previousCount: x?.previous_count,
        order,
      };
    })
    .sort((a, b) => a.order - b.order);

  volumAnalysisMapData.data.data = data;
  volumAnalysisMapData.shouldShowGraph = response?.length > 0;
  if (response) {
    volumAnalysisMapData.originalData = response;
  }
  return volumAnalysisMapData;
};

export const useDashboardPeopleAnalysisData = (filters, people, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard_people-volume-analysis', filters, people],
    queryFn: () => getDashboardVolumeAnalysisData(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
// people sentiments
export const getPeopleSentimentChartData = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/source-sentiment-analysis?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const sentimentChartMapData = JSON.parse(
    JSON.stringify(topSourceBySentiment)
  );

  sentimentChartMapData.data.data = response.data.map((item) => ({
    label: item.label,
    neg: item.negative.toString(),
    pos: item.positive.toString(),
    neu: item.neutral.toString(),
  }));
  sentimentChartMapData.data.summary.value = String(
    addCountPrefix(response?.total_count)
  );
  sentimentChartMapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    sentimentChartMapData.originalData = response;
  }
  return sentimentChartMapData;
};

export const usePeopleDashboardSentimentChartData = (
  filters,
  people,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-people_sentiment-analysis', filters, people],
    queryFn: () => getPeopleSentimentChartData(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const encloseWordsInDoubleQuotes = (data) => {
  return data.map((item) => {
    const regex = /^"(.*)"$/;
    if (regex.test(item)) {
      return item;
    }
    return `"${item}"`;
  });
};
// get journalist data
export const getPeopleJournalistSentimetChart = async (payload, people) => {
  people.map((item) => `"${item}"`);
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/journalist-sentiment-analysis?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(topJournalistBySentiment));

  mapData.data.data = response.data.map((item) => {
    const label = toCamelCase(item?.label);
    return {
      author_id: item.author_id,
      label,
      positive: item.positive,
      negative: item.negative,
      neutral: item.neutral,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleDashboardJournalistSentimetChart = (
  filters,
  people,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-people_journalist_sentiment', filters, people],
    queryFn: () => getPeopleJournalistSentimetChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// get coverage
export const getPeopleCoverageChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/coverage-over-time?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peopleCoverage));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Traditional';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => traditional?.includes(x))
    : [...traditional];

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.label ?? x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: String(x?.value),
      date: x?.label ?? x?.date,
      color: purpleColorGradients?.purple60,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleCoverageChart = (filters, people, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-people_coverage', filters, people],
    queryFn: () => getPeopleCoverageChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getPeopleCoverageSocialChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/social-coverage-over-time?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peopleCoverage));

  const selectedMedia = getSelectedTypes(payload);
  if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Social';
  }

  mapData.mediaTypes = payload?.media_types
    ? payload?.media_types?.filter((x) => social.includes(x))
    : [...social];

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.label ?? x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: String(x?.value),
      date: x?.label ?? x?.date,
      color: purpleColorGradients?.purple60,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleCoverageSocialChart = (filters, people, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-people_coverage_social', filters, people],
    queryFn: () => getPeopleCoverageSocialChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// get popular topics
export const getPeoplePopularTopicsChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/top-themes?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(peoplePopularTopics));

  mapData.data.data = response?.data?.map((item, i) => {
    const colorIndex = i % colors?.length;
    return {
      label: item.label,
      value: item.article_count,
      ...colors[colorIndex],
    };
  });

  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeoplePopularTopicsChart = (filters, people, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-people_popular_topics', filters, people],
    queryFn: () => getPeoplePopularTopicsChart(filters, people),
    enabled: !!isEnabled,
    refetchOnWindowFocus: false,
  });
};

// Function to generate random colors
function getRandomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

// get media type
export const getPeopleMediaTypeChart = async (payload, people) => {
  const queryString = `${objectToQueryString({
    ...payload,
    person_name: encloseWordsInDoubleQuotes(people),
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-people/media-type?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(mediaType));

  let totalOnlineCount = 0;
  let totalPrintCount = 0;
  let totalBlogsCount = 0;
  let totalForumsCount = 0;
  let totalReviewsCount = 0;
  let totalTwitterCount = 0;
  let totalRedditCount = 0;
  response?.data?.forEach((x) => {
    x.media_type_count = Array.isArray(x?.media_type_count)
      ? x?.media_type_count
      : [x?.media_type_count];

    const onlineMedia = x?.media_type_count?.find(
      (media) => media.label === 'Online'
    );
    const PrintMedia = x?.media_type_count?.find(
      (media) => media.label === 'Print'
    );
    const forumMedia = x?.media_type_count?.find(
      (media) => media.label === 'Forums'
    );
    const blogMedia = x?.media_type_count?.find(
      (media) => media.label === 'Blogs'
    );
    const reviewMedia = x?.media_type_count?.find(
      (media) => media.label === 'Reviews'
    );
    const twitterMedia = x?.media_type_count?.find(
      (media) => media.label === 'X (Twitter)'
    );
    const redditMedia = x?.media_type_count?.find(
      (media) => media.label === 'Reddit'
    );
    const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
    const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
    const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
    const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
    const twitterDocCount = twitterMedia ? twitterMedia?.doc_count : 0;
    const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
    const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
    totalOnlineCount += onlineDocCount;
    totalPrintCount += printDocCount;
    totalBlogsCount += blogDocCount;
    totalReviewsCount += reviewDocCount;
    totalForumsCount += forumDocCount;
    totalTwitterCount += twitterDocCount;
    totalRedditCount += redditDocCount;
  });

  mapData.data.data = response.data.map((item) => {
    const formattedDate = getFormattedDate(item?.label || item?.date);
    item.media_type_count = Array.isArray(item?.media_type_count)
      ? item?.media_type_count
      : [item?.media_type_count];
    return {
      date: item?.label || item?.date,
      label: formattedDate,
      print:
        item.media_type_count.find((type) => type.label === 'Print')?.value ||
        0,
      online:
        item.media_type_count.find((type) => type.label === 'Online')?.value ||
        0,
      forum:
        item.media_type_count.find((type) => type.label === 'Forums')?.value ||
        0,
      blog:
        item.media_type_count.find((type) => type.label === 'Blogs')?.value ||
        0,
      review:
        item.media_type_count.find((type) => type.label === 'Reviews')?.value ||
        0,
      twitter:
        item.media_type_count.find((type) => type.label === 'X (Twitter)')
          ?.value || 0,
      reddit:
        item.media_type_count.find((type) => type.label === 'Reddit')?.value ||
        0,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = {
    ...mapData.data.summary,
    totalOnlineCount,
    totalPrintCount,
    totalBlogsCount,
    totalForumsCount,
    totalReviewsCount,
    totalTwitterCount,
    totalRedditCount,
    mediaType: response?.media_filter ?? null,
  };

  mapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const usePeopleMediaTypeChart = (filters, people, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-people_media_type', filters, people],
    queryFn: () => getPeopleMediaTypeChart(filters, people),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
