import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  IconBox,
  Iconwpr,
  StyledMarkdown,
  TopCommentarySection,
  TopInfluencerBodyText,
  TopInfluencerHeader,
  TopInfluencerHeaderLeft,
  TopInfluencerHeaderTitle,
  TopInfluencerHeaderWrp,
  TopInfluencerIconWrp,
  TopInfluencerLogo,
  TopInfluencerLogoTextWrp,
  TopInfluencerSummaryWrapper,
  TopInfluencerTableBody,
  TopInfluencerTableBodyWrp,
  TopInfluencerTableHdr,
  TopInfluencerTableWrp,
  TopInfluencerTitle,
  TopSlotBody,
  TopSlotHeader,
  GraphNoDataText,
} from './index.sc';
import SimpleReusableDropDown from '../simple-dropdown';
import { VerticleDots } from '../../assets/icons/VerticleDots';
import { reader } from '../../constants';
import ChartToolTip from '../chart-tool-tip';
import HelpIcon from '../../assets/icons/HelpIcon';
import { useSelector } from 'react-redux';
import Edit2 from '../../assets/icons/Edit2';
import SlotOverview from '../search-result/slot-details/SlotOverview';
import ThreeDotsLoader from '../three-dots-loader';
import DownPolygon from '../../assets/icons/DownPolygon';
import { formatNumber } from '../../utils';

const TopInfluencer = ({
  widget,
  type = 'dashboard',
  dashboardId,
  helperText,
  editChart,
  dashboardType = 'overview',
  setSelectedComponent,
  isSavePopup,
  editOption,
  actionOption = false,
  downloadFunction,
  onDownloadChartData,
  graphDownloading,
  insight = {},
  insightLoading = false,
  setArticlePosition,
  handleDrillDown = () => {},
  widgetClassName,
  downloading = true,
}) => {
  const [toggleChartTip, setToggleChartTip] = useState(false);
  const titleRef = useRef(null);
  const downloadRef = useRef(null);
  const containerRef = useRef(null);

  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [sortedData, setSortedData] = useState(widget?.data?.data || []);

  const [editChart2, setEditChart2] = useState({
    chartName: editChart?.chartName || widget?.title,
    chartType: widget.graphType || '',
  });

  const role = useSelector((state) => state?.user?.role);

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown(!openActionDropdown);
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;
      setIconPosition({
        left: titleWidth + 24,
      });
    }
  }, [editChart?.chartName, editChart2?.chartName]);

  useEffect(() => {
    setSortedData(widget?.data?.data || []);
  }, [widget?.data?.data]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sorted = [...sortedData].sort((a, b) => {
      // If key is totalLikes or totalReach, sort based on those properties
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  const graphData = widget;

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      type: 'Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, graphData, editChart?.chartName);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, graphData);
      },
    },
  ];

  const isSortableColumn = (value) => {
    // Check if the first data item for this column is a number
    return typeof sortedData[0]?.[value] === 'number';
  };

  const isLogoColumn = (value) => {
    // Assuming columns that might have logos are named 'source'
    return ['source'].includes(value);
  };

  const handleResults = (data) => {
    handleDrillDown({
      widget: widget?.title,
      graphSelection: data.hashtag || data.subredditName || data.authorName,
      rawData: data,
      otherInfo: {
        ...data,
        uniqueId: widget?.component,
        widget: { component: widget?.component },
      },
    });
    setArticlePosition('left');
  };

  return (
    <TopInfluencerTableWrp
      type={type}
      ref={containerRef}
      className={widgetClassName}
    >
      <TopInfluencerHeader>
        <TopInfluencerHeaderLeft
          className="hide-download"
          style={{ marginLeft: '1.5rem' }}
        >
          <TopInfluencerTitle ref={titleRef}>
            {dashboardId ? editChart?.chartName : editChart2?.chartName}
          </TopInfluencerTitle>
          <>
            <span
              style={{ marginTop: '0.25rem', cursor: 'pointer' }}
              onMouseEnter={() => setToggleChartTip(true)}
              onMouseLeave={() => setToggleChartTip(false)}
              className="hide-downloading"
            >
              <HelpIcon />
            </span>
            {toggleChartTip && (
              <ChartToolTip
                text={helperText}
                dashboardType={dashboardType === 'custom'}
                componentLeft={iconPosition?.left}
                componentTop={8.5}
              />
            )}
          </>
        </TopInfluencerHeaderLeft>

        <IconBox isSavePopup={isSavePopup} style={{ marginRight: '1.5rem' }}>
          {/* {role !== reader && (
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              onClick={handleGraphEditClick}
              className="hide-downloading"
            >
              <Edit2 />
            </Iconwpr>
          )} */}

          {role !== reader && (
            <>
              <Iconwpr
                width={'1.5rem'}
                height={'1.5rem'}
                onClick={(e) => {
                  handleOptionIcon(e, graphData.component);
                }}
                ref={downloadRef}
                className="hide-downloading hide-download "
              >
                <VerticleDots
                  color={openActionDropdown ? '#675ef2' : '#5C5E60'}
                />
                <SimpleReusableDropDown
                  isOpen={openActionDropdown}
                  options={actionDropDownOptions}
                  graphDownloading={graphDownloading}
                  setIsOpen={setOpenActionDropDown}
                />
              </Iconwpr>
            </>
          )}
        </IconBox>
      </TopInfluencerHeader>
      {type === 'dashboard' && (
        <TopInfluencerSummaryWrapper
          style={{ marginBottom: '10px', marginLeft: '1.5rem' }}
          className="hide-download"
        >
          <SlotOverview summary={graphData?.data?.summary} />
        </TopInfluencerSummaryWrapper>
      )}
      <div>
        {sortedData?.length > 0 ? (
          <>
            <TopSlotHeader>
              {widget?.data?.header?.map((tableData, id) => {
                const isLastItem = id === widget?.data?.header?.length - 1;
                return (
                  <TopInfluencerTableHdr key={id}>
                    <TopInfluencerHeaderWrp>
                      <TopInfluencerHeaderTitle
                        align={isLastItem ? 'flex-end' : 'flex-start'}
                        index={tableData.value === 'subreddit_name'}
                      >
                        {tableData.label}
                        {isSortableColumn(tableData.value) && (
                          <div
                            onClick={() => handleSort(tableData.value)}
                            style={{ cursor: 'pointer' }}
                          >
                            {sortConfig.key === tableData.value ? (
                              sortConfig.direction === 'asc' ? (
                                <DownPolygon fill={'#585858'} isOpen={false} />
                              ) : (
                                <DownPolygon fill={'#585858'} isOpen={true} />
                              )
                            ) : (
                              <DownPolygon fill={'#585858'} isOpen={false} />
                            )}
                          </div>
                        )}
                      </TopInfluencerHeaderTitle>
                    </TopInfluencerHeaderWrp>
                  </TopInfluencerTableHdr>
                );
              })}
            </TopSlotHeader>
            <TopSlotBody className="data-table">
              {sortedData?.map((tableBody, id) => (
                <TopInfluencerTableBodyWrp key={id}>
                  <TopInfluencerTableBody
                    onClick={() => handleResults(tableBody)}
                  >
                    {widget?.data?.header?.map((header, index) => {
                      const isLastItem =
                        index === widget?.data?.header?.length - 1;
                      return (
                        <TopInfluencerBodyText
                          key={index}
                          align={isLastItem ? 'flex-end' : 'flex-start'}
                          index={header.value === 'subreddit_name'}
                        >
                          {isLogoColumn(header.value) ? (
                            <TopInfluencerLogoTextWrp>
                              <TopInfluencerIconWrp>
                                {!downloading && (
                                  <TopInfluencerLogo
                                    className="hide-download"
                                    src={tableBody.logoUrl}
                                  />
                                )}
                              </TopInfluencerIconWrp>
                              <span>{tableBody[header.value]}</span>
                            </TopInfluencerLogoTextWrp>
                          ) : typeof tableBody[header.value] === 'number' ? (
                            formatNumber(tableBody[header.value])
                          ) : (
                            tableBody[header.value]
                          )}
                        </TopInfluencerBodyText>
                      );
                    })}
                  </TopInfluencerTableBody>
                </TopInfluencerTableBodyWrp>
              ))}
            </TopSlotBody>
          </>
        ) : (
          <GraphNoDataText>No Data</GraphNoDataText>
        )}
      </div>
      {/* {dashboardType === 'overview' && (
        <div>
          <TopCommentarySection className="hide-download">
            {!insightLoading ? (
              <StyledMarkdown>{insight?.data?.summary}</StyledMarkdown>
            ) : (
              <div
                style={{
                  display: 'flex',
                  minHeight: '3rem',
                  alignItems: 'center',
                }}
              >
                <ThreeDotsLoader />
              </div>
            )}
          </TopCommentarySection>
        </div>
      )} */}
    </TopInfluencerTableWrp>
  );
};

export default TopInfluencer;

TopInfluencer.propTypes = {
  widget: PropTypes.arrayOf(object),
  dashboardId: PropTypes.string,
  type: PropTypes.string,
  editChart: PropTypes.object,
  helperText: PropTypes.string,
  dashboardType: PropTypes.string,
  setSelectedComponent: PropTypes.func,
  isSavePopup: PropTypes.bool,
  editOption: PropTypes.bool,
  actionOption: PropTypes.bool,
  downloadFunction: PropTypes.func,
  graphDownloading: PropTypes.bool,
  onDownloadChartData: PropTypes.func,
  insightLoading: PropTypes.bool,
  insight: PropTypes.object,
  handleDrillDown: PropTypes.func,
  setArticlePosition: PropTypes.func,
  widgetClassName: PropTypes.func,
  downloading: PropTypes.bool,
};
