import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  AIIconWrp,
  AIText,
  IconWrp,
  AiSection,
  CommentaryLabel,
  CommentarySection,
  GraphTypeBtn,
  GraphTypeBtnWrapper,
  IconBox,
  Iconwpr,
  LegendSection,
  LineShow,
  // SlotBody,
  SlotSubTitle,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
  LegendBox,
  LegendLabel,
} from './index.sc';
import { GraphNoDataText, SlotBody } from '../../index.sc';
import { graphTypes, widgetMapping } from '../../../../constants/widgets';
import { VerticleDots } from '../../../../assets/icons/VerticleDots';
// import Loader from '../../../loader';
import PortalTooltip from '../../../portal-tooltip';
import GraphTooltip from '../../../graph-tooltip';
import GraphLegend from '../../../graph-legend';
import CircularLoading from '../../../../assets/icons/loading/circularLoading';
import DownloadIcon from '../../../../assets/icons/DownloadIcon';
import SimpleReusableDropDown from '../../../simple-dropdown';
import HelpIcon from '../../../../assets/icons/HelpIcon';
import AiIcon from '../../../../assets/icons/AiIcon';
import Tooltip from '../../../icon-tooltip';
import ChartToolTip from '../../../chart-tool-tip';
import {
  blueColorGradients,
  coolGrayColorGradients,
  cyanColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
  yellowColorGradients,
} from '../../../../constants/graph-colors';
import { formatNumber } from '../../../../graphs/utils/graphGrid';
import ThreeDotsLoader from '../../../three-dots-loader';

const gridXTicksCount = 6;

const tabChartConfig = {
  trendline: 'area',
  bar: 'column',
};

const social = ['X (Twitter)', 'Blogs', 'Forums', 'Reviews', 'Reddit'];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (filters?.mediaType !== null) {
    const mediaTypes = filters?.mediaType?.split(',');
    const isSocial = mediaTypes?.some((x) => social?.includes(x));
    const isTraditional = mediaTypes?.some((x) => traditional?.includes(x));
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  // console.log(widgetMapping, dashboardType, widget.component);

  const widgetDetails = {
    dashboardType,
    type,
    component: widget?.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget?.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget?.graphType]?.config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    xAxisROTStartEndTicks: true,
  };

  return widget?.shouldShowGraph ? (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  ) : (
    <GraphNoDataText>No Data</GraphNoDataText>
  );
};

const ResultOverTime = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  legend = false,
  commentary = false,
  resetSelection = false,
  handleOnClick = () => {},
  actionOption,
  showChangeGraphOptions = true,
  downloadFunction,
  setSelectedComponent,
  selectedComponent,
  graphDownloading,
  widgetClassName,
  helperText = '',
  onDownloadChartData,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [tooltipEvent, setTooltipEvent] = useState({});
  const containerRef = useRef(null);

  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const [chartTooltip, setChartTooltip] = useState(false);

  const [activeBtn, setActiveBtn] = useState('trendline');
  const [selectedWidget, setSelectedWidget] = useState(() => {
    if (widget && tabChartConfig && tabChartConfig[activeBtn]) {
      return widget[tabChartConfig[activeBtn]];
    } else {
      return null;
    }
  });

  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const downloadRef = useRef(null);

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (widget && tabChartConfig && tabChartConfig[activeBtn]) {
      setSelectedWidget(
        JSON.parse(JSON.stringify(widget[tabChartConfig[activeBtn]]))
      );
    }
  }, [activeBtn, widget, tabChartConfig]);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({ data: tData, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({});
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const customHandleClick = (event, d) => {
    handleOnClick(event, d, selectedWidget);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  const actionDropDownOptions = [
    {
      label: 'Download Image',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, widget, selectedWidget?.title);
        setOpenActionDropDown(false);
      },
      type: 'Image',
    },
    {
      label: 'Download Pdf',
      type: 'PDF',
      clickFunction: (option) => {
        downloadFunction(option, containerRef, widget, selectedWidget?.title);
        setOpenActionDropDown(false);
      },
    },
    {
      label: 'Download Excel',
      type: 'xlsx',
      clickFunction: (option) => {
        onDownloadChartData(option, widget);
      },
    },
    {
      label: 'Download CSV',
      type: 'csv',
      clickFunction: (option) => {
        onDownloadChartData(option, widget);
      },
    },
  ];

  let legendData = [];
  const twoD = selectedWidget?.data?.labels?.length > 1;

  if (twoD) {
    legendData = selectedWidget?.data?.labels.map((ele) => ({
      label: ele?.label,
      value: ele?.label?.replaceAll(' ', '').toLowerCase(),
      color: ele?.color,
    }));
  } else {
    legendData = selectedWidget?.data?.data?.map((ele) => ({
      label: ele?.label,
      value: ele?.value,
      color: ele?.color,
    }));
  }

  const handleTabBtnClick = (e, tab) => {
    e.stopPropagation();
    setActiveBtn(tab);
    // handleUpdatedChart({
    //   chartName: selectedWidget.title,
    //   chartType: tabChartConfig[tab],
    //   customWidget: true,
    // });
  };
  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown((prev) =>
      prev !== componentName ? componentName : false
    );
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth + 48, // Added width of icon and padding
      });
    }
  }, [helperText]);

  const traditional = {
    type: 'Traditional',
    color: purpleColorGradients.purple60,
    dataKey: 'totalTraditionCount',
  };

  const social = {
    type: 'Social',
    color: magentaColorGradients.magenta50,
    dataKey: 'totalSocialCount',
  };

  const mediaMappings = {
    social: [
      {
        ...social,
        type: 'Results',
      },
    ],
    traditional: [
      {
        ...traditional,
        type: 'Results',
      },
    ],
    all: [social, traditional],
  };

  const summary = selectedWidget?.data?.summary;
  const mediaType = summary?.mediaType;
  const mediaTypeArray = mediaType
    ? mediaType.split(',').map((type) => type.trim().toLowerCase())
    : null;

  const selectedMedia = getSelectedTypes(summary);

  return (
    <SlotDetailsMainWrp ref={containerRef} className={widgetClassName}>
      <IconBox
        type={type}
        style={{ marginRight: '1.5rem' }}
        className="hide-download"
      >
        {/* <Iconwpr width={'1.5rem'} height={'1.5rem'} onClick={handleClick}>
          <ExpandIcon />
        </Iconwpr> */}
        {showChangeGraphOptions && (
          <GraphTypeBtnWrapper className="set-opacity-downloading hide-download">
            <GraphTypeBtn
              className={activeBtn === 'trendline' ? 'active' : ''}
              onClick={(e) => handleTabBtnClick(e, 'trendline')}
            >
              Trendline
            </GraphTypeBtn>
            <GraphTypeBtn
              className={activeBtn === 'bar' ? 'active' : ''}
              onClick={(e) => handleTabBtnClick(e, 'bar')}
            >
              Bar
            </GraphTypeBtn>
          </GraphTypeBtnWrapper>
        )}
        {selectedWidget?.data?.data && actionOption && (
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            onClick={(e) => handleOptionIcon(e, 'result_over_time')}
            ref={downloadRef}
            className="hide-downloading"
          >
            <VerticleDots
              color={
                openActionDropdown === selectedComponent ? '#675ef2' : '#5C5E60'
              }
            />
            <SimpleReusableDropDown
              isOpen={openActionDropdown === selectedComponent}
              options={actionDropDownOptions}
              graphDownloading={graphDownloading}
              setIsOpen={setOpenActionDropDown}
            />
          </Iconwpr>
        )}
      </IconBox>
      <SlotDetailsWrp>
        <SlotHeader className="hide-download">
          <SlotHeaderLeft>
            <SlotTitle ref={titleRef} style={{ marginLeft: '1.5rem' }}>
              {selectedWidget?.title}
            </SlotTitle>
            {/* <Tooltip content="Result over time Graph"> */}
            <span
              style={{ marginTop: '0.25rem', cursor: 'pointer' }}
              onMouseEnter={() => setChartTooltip(true)}
              onMouseLeave={() => setChartTooltip(false)}
            >
              <HelpIcon />
            </span>
            {/* </Tooltip> */}
          </SlotHeaderLeft>
          {chartTooltip && (
            <ChartToolTip
              text={helperText}
              componentTop={10}
              componentLeft={iconPosition?.left}
            />
          )}
        </SlotHeader>
        <SlotSubTitle
          style={{ marginLeft: '1.5rem' }}
          className="hide-download"
        >
          {selectedWidget?.data?.summary?.value} Articles
        </SlotSubTitle>
        <SlotBody
          type={type}
          className={`${type === 'dashboard' && legend ? 'legend' : ''} ${
            type === 'dashboard' && commentary ? 'commentary' : ''
          } `}
          style={{ marginLeft: activeBtn === 'bar' ? '0rem' : '1.5rem' }}
        >
          {loader ? (
            // <Loader />
            <CircularLoading width="1.8rem" height="1.8rem" size="0.3rem" />
          ) : (
            generateGraphComponent(
              selectedWidget,
              defaultConfig,
              type,
              dashboardType,
              canvas,
              resetSelection
            )
          )}
          {/* {tabChartConfig[activeBtn] === 'area' &&
            Object.keys(tooltipEvent).length > 0 && (
              <div className="result_tooltip">
                <LineShow xAxis={tooltipEvent?.event?.offsetX + 2}></LineShow>
              </div>
            )} */}
          {tooltipEnabled && enableTooltip && (
            <PortalTooltip
              isOpen={true}
              pos={toolTipPos}
              align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
              vAlign={
                toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
              }
              boxShadow="0px 8px 20px 0px rgba(0, 0, 0, 0.12)"
            >
              {' '}
              <div className="result_tooltip">
                <GraphTooltip
                  tooltipData={tooltipData}
                  type="two-d"
                  widget={selectedWidget}
                />
              </div>
            </PortalTooltip>
          )}
        </SlotBody>
        {legend && (
          <SlotFooter>
            {' '}
            <div>
              {/* <AiSection>
              <AiPurpleIcon />
            </AiSection>
            <CommentarySection>
              {!insightLoading ? (
                <CommentaryLabel>{insight?.data?.summary}</CommentaryLabel>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    minHeight: '3rem',
                    alignItems: 'center',
                  }}
                >
                  <ThreeDotsLoader />
                </div>
              )}
            </CommentarySection> */}
            </div>
            <div style={{ display: 'flex' }}>
              {selectedMedia === 'all' &&
                mediaMappings.all.map((item) => {
                  return (
                    <React.Fragment key={item.type}>
                      <LegendBox bgColor={item.color} />
                      &nbsp;
                      <LegendLabel>
                        {item.type} :{' '}
                        {summary?.[item.dataKey]
                          ? formatNumber(summary[item.dataKey])
                          : 0}
                      </LegendLabel>
                      <LegendLabel> &nbsp; | &nbsp;</LegendLabel>
                    </React.Fragment>
                  );
                })}
              {selectedMedia === 'traditional' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for traditional channels
                </p>
              )}
              {selectedMedia === 'social' && (
                <p
                  style={{
                    color: coolGrayColorGradients.coolGray60,
                    fontSize: '11px',
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                  }}
                >
                  Trendline for social channels
                </p>
              )}
            </div>
          </SlotFooter>
        )}
      </SlotDetailsWrp>
    </SlotDetailsMainWrp>
  );
};

ResultOverTime.defaultProps = {
  type: 'dashboard',
};

ResultOverTime.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  legend: Proptypes.bool,
  commentary: Proptypes.bool,
  actionOption: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  showChangeGraphOptions: Proptypes.bool,
  downloadFunction: Proptypes.func,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
  widgetClassName: Proptypes.string,
  helperText: Proptypes.string,
  onDownloadChartData: Proptypes.func,
  selectedComponent: Proptypes.string,
};

export default ResultOverTime;
