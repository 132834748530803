import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  FullSlot,
  LegendLabel,
  LegendValue,
  PRNoDataTxt,
  // IconBox,
  // Iconwpr,
  SlotBody,
  SlotBodyHeader,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  // SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotHeaderTabsWrp,
  SlotLeftHeaderTxtWrp,
  SlotSubTitle,
  SlotTabItems,
  SlotTitle,
  TooltipLegendContainer,
  TooltipWrapper,
} from './index.sc';
// import Edit2 from '../../../assets/icons/Edit2';
// import { VerticleDots } from '../../../assets/icons/VerticleDots';
import Loader from '../../loader';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import PRImpactHeader from './pr-impact-header';
import // CommentaryLabel,
// CommentarySection,
'../../search-result/index.sc';
import HelpIcon from '../../../assets/icons/HelpIcon';
import ChartToolTip from '../../chart-tool-tip';
import {
  greenColorGradients,
  orangeColorGradients,
  redColorGradients,
} from '../../../constants/graph-colors';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false,
  brandKeyword
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType]?.component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    yAxisType: 'percentage',
    yAxisTicksFormat: true,
    valueInPercent: true,
    prImpact: true,
    negValueInvolved: widget?.data?.data?.some((x) => x.value < 0),
    dashboardType: dashboardType === 'overview',
    onlyNegValueInvolved: widget?.data?.data?.every((x) => x.value < 0),
    brandKeyword,
  };

  return (
    <GraphComponent
      data={widget.data}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const PRImpact = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  handleOnClick = () => {},
  handleUpdatedChart,
  customClassName = 'primpatct',
  helperText,
  isDocDownloading,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [chartToolTip, setChartToolTip] = useState();
  const titleRef = useRef(null);
  const [iconPosition, setIconPosition] = useState({ left: 0, top: 0 });

  const [activePRTab, setActivePRTab] = useState(0);

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({
        data: { ...tData, value: tData?.count },
        rawData: d?.rawData,
      });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };
  const customHandleClick = (event, d) => {
    handleOnClick(event, d);
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick: customHandleClick,
  };

  const getColor = (color) => {
    if (color === redColorGradients?.red50) {
      return 'Very Poor';
    } else if (color === orangeColorGradients?.orange40) {
      return 'Poor';
    } else if (color === greenColorGradients?.green30) {
      return 'Good';
    } else if (color === greenColorGradients?.green40) {
      return 'Very Good';
    }
    return 'Excellent';
  };

  useEffect(() => {
    if (titleRef.current) {
      const titleWidth = titleRef.current.offsetWidth;

      setIconPosition({
        left: titleWidth, // Added width of icon and padding
      });
    }
  }, [helperText, activePRTab]);

  return (
    <>
      <FullSlot
        className="graph-widget override-padding hide-download"
        // selected={idx === selected}
        // onClick={(e) => handleClick(idx, e)}
      >
        <SlotDetailsMainWrp className={customClassName}>
          <SlotDetailsWrp>
            <SlotHeaderTabsWrp>
              {widget?.data?.map((keyword, id) => (
                <SlotTabItems
                  onClick={() => {
                    setActivePRTab(id);
                  }}
                  activeTab={activePRTab === id}
                  key={id}
                >
                  {keyword?.brandKeyword}
                </SlotTabItems>
              ))}
            </SlotHeaderTabsWrp>
            <SlotHeader>
              <SlotHeaderLeft>
                <SlotLeftHeaderTxtWrp>
                  <SlotTitle ref={titleRef}>
                    {`PR Impact Scale - ${
                      widget?.prBrandKeywords[activePRTab] &&
                      widget?.prBrandKeywords[activePRTab]
                    }`}
                    <div
                      style={{
                        marginTop: '0.25rem',
                        marginLeft: '0.5rem',
                        cursor: 'pointer',
                      }}
                      onMouseEnter={() => setChartToolTip(true)}
                      onMouseLeave={() => setChartToolTip(false)}
                    >
                      <HelpIcon />
                    </div>
                  </SlotTitle>
                  {widget?.data[activePRTab]?.totalCount && (
                    <SlotSubTitle>
                      {widget?.data[activePRTab]?.totalCount} Articles
                    </SlotSubTitle>
                  )}
                </SlotLeftHeaderTxtWrp>
              </SlotHeaderLeft>
              {chartToolTip && (
                <ChartToolTip
                  text={helperText}
                  componentLeft={iconPosition?.left}
                  componentTop={14.5}
                />
              )}
            </SlotHeader>
            {loader ? (
              <Loader />
            ) : (
              <SlotBody type={type} className="commentary">
                <SlotBodyHeader>
                  <PRImpactHeader
                    dashboardType={dashboardType}
                    graphData={widget?.data[activePRTab]?.gauge}
                  />
                </SlotBodyHeader>
                <SlotBodyMain>
                  {widget?.data[activePRTab] !== undefined ? (
                    generateGraphComponent(
                      widget?.data[activePRTab]?.column,
                      defaultConfig,
                      type,
                      dashboardType,
                      canvas,
                      resetSelection,
                      widget?.data[activePRTab]?.brandKeyword
                    )
                  ) : (
                    <PRNoDataTxt>No Data</PRNoDataTxt>
                  )}
                  {enableTooltip && (
                    <PortalTooltip
                      isOpen={true}
                      pos={toolTipPos}
                      align={
                        toolTipPos.left > window.innerWidth / 2
                          ? 'left'
                          : 'right'
                      }
                      vAlign={
                        toolTipPos.top > window.innerHeight / 2
                          ? 'top'
                          : 'bottom'
                      }
                    >
                      <TooltipWrapper className="two-d">
                        <TooltipLegendContainer>
                          <LegendLabel>Date:</LegendLabel>
                          <LegendValue>{tooltipData?.data?.label}</LegendValue>
                        </TooltipLegendContainer>

                        <TooltipLegendContainer>
                          <LegendLabel>Value:</LegendLabel>
                          <LegendValue>
                            {tooltipData?.data?.accValue.toFixed(2)}%
                          </LegendValue>
                        </TooltipLegendContainer>

                        <TooltipLegendContainer>
                          <LegendLabel>Impact:</LegendLabel>
                          <LegendValue>
                            {getColor(tooltipData?.data?.color)}
                          </LegendValue>
                        </TooltipLegendContainer>
                      </TooltipWrapper>
                    </PortalTooltip>
                  )}
                </SlotBodyMain>
              </SlotBody>
            )}
            {/* <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter> */}
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
      {isDocDownloading &&
        widget?.data?.map?.((prItems, id) => (
          <FullSlot
            key={id}
            // className="graph-widget override-padding primpact-download"
            className={customClassName + '_' + prItems?.brandKeyword}

            // selected={idx === selected}
            // onClick={(e) => handleClick(idx, e)}
          >
            {/* <SlotDetailsMainWrp
            key={id}
            className={
              customClassName +
              '_' +
              prItems?.brandKeyword +
              ' ' +
              'primpact-download'
            }
          > */}
            <SlotDetailsWrp>
              {loader ? (
                <Loader />
              ) : (
                <SlotBody type={type} className="commentary">
                  <SlotBodyHeader>
                    <PRImpactHeader
                      dashboardType={dashboardType}
                      graphData={prItems?.gauge}
                    />
                  </SlotBodyHeader>
                  <SlotBodyMain>
                    {prItems?.column?.data?.data !== undefined ? (
                      generateGraphComponent(
                        prItems?.column,
                        defaultConfig,
                        type,
                        dashboardType,
                        canvas,
                        resetSelection,
                        widget?.data[activePRTab]?.brandKeyword
                      )
                    ) : (
                      <PRNoDataTxt>No Data</PRNoDataTxt>
                    )}
                  </SlotBodyMain>
                </SlotBody>
              )}
            </SlotDetailsWrp>
            {/* </SlotDetailsMainWrp> */}
          </FullSlot>
        ))}
    </>
  );
};

export default PRImpact;

PRImpact.defaultProps = {
  type: 'dashboard',
};

PRImpact.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  customClassName: Proptypes.string,
  helperText: Proptypes.string,
  isDocDownloading: Proptypes.bool,
};
